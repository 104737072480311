$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

$notosans-fontface-path: "~notosans-fontface/fonts";
@import "~notosans-fontface/scss/notosans-fontface-allweight";

$hc-icons-font-path: "~@healthcatalyst/cashmere/hcicons";
@import "~@healthcatalyst/cashmere/hcicons/hcicons";

body {
  margin: 0px;
}
